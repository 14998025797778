import React from 'react'
// , { useEffect }
import { graphql } from 'gatsby'
// import { Link } from "gatsby"
// import TransitionLink from "gatsby-plugin-transition-link"
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import { HelmetDatoCms } from 'gatsby-source-datocms'

// import SEO from "../components/seo"
import Layout from "../components/layout"
import Header from "../components/header"
import Date from "../components/date"
import SvgNotes from "../components/svgNotes"
import Divider from "../components/divider"
import GetInTouch from "../components/GetInTouch"

const Notes = ({ data: { blog_post } }) => (
  <div className="notes">
    <Layout>
      {/* <HelmetDatoCms seo={data.datoCmsBlogPost.seoMetaTags} /> */}
      <div className="section-container">
        <Header data-scroll data-scroll-speed="-1" data-scroll-position="top" />
        <div className="heroSvg" data-scroll data-scroll-speed="2" data-scroll-position="top">
          <SvgNotes />
        </div>
      </div>

      <div className="section section-container">
        <div className="row">
          <div className="col-lg-6 offset-lg-3 posts">
            {blog_post.edges.map((post, i) => {
              return (
                <div className="blog-post">
                  <div className="date">
                    <Date toParse={post.node.date} /> - ({post.node.readingTime} min read)
                  </div>
                  <h2 className="title">
                    {post.node.title}
                  </h2>
                  <div className="short-desc">
                    {post.node.excerpt}
                  </div>
                  <div>
                    <AniLink cover to={post.node.slug} className="btn btn-blog" bg="#060606" duration={1} direction="top">
                      Read the post
                    </AniLink>
                    {/* <Link to={post.node.slug} className="btn btn-blog">Read the post</Link> */}
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <Divider Color="white" />

      <GetInTouch />

    </Layout>
  </div>
)

export default Notes

export const query = graphql`
query AllNotes {
  blog_post: allDatoCmsBlogPost(sort: {fields: id, order: DESC}) {
    edges {
      node {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
        title
        excerpt
        slug
        readingTime
        date(formatString: "DD-MM-YYYY")
      }
    }
  }
}
`

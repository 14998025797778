import React from "react"

const Date = ({ toParse }) => {
  let date = toParse;
  let day = date.substring(0, 2);
  let month = parseInt(date.substring(4, 5));
  let year = date.substring(6, 10);
  let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  switch (month) {
    case 1:
      date = months[0] + ' ' + day + ', '  + year
      break;
    case 2:
      date = months[1] + ' ' + day + ', '  + year
      break;
    case 3:
      date = months[2] + ' ' + day + ', '  + year
      break;
    case 4:
      date = months[3] + ' ' + day + ', '  + year
      break;
    case 5:
      date = months[4] + ' ' + day + ', '  + year
      break;
    case 6:
      date = months[5] + ' ' + day + ', '  + year
      break;
    case 7:
      date = months[6] + ' ' + day + ', '  + year
      break;
    case 8:
      date = months[7] + ' ' + day + ', '  + year
      break;
    case 9:
      date = months[8] + ' ' + day + ', '  + year
      break;
    case 10:
      date = months[9] + ' ' + day + ', '  + year
      break;
    case 11:
      date = months[10] + ' ' + day + ', '  + year
      break;
    case 12:
      date = months[11] + ' ' + day + ', '  + year
      break;
    default:
      date = 'Not set'
  }

  return (
    <span>{date}</span>
  );
};

export default Date